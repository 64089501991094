<template>
  <md-card>
    <md-card-header>
      <div>
        <div class="md-layout md-size-60 md-alignment-center header-step">
          <p class="md-headline">Select Your Faves</p>
          <p class="sub-headline">Pick your favorites sites below, you'll be able to add more later...</p>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <div class="middle-section">
        <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="isLoading"/>

        <ul class="thumbnails">
          <!-- first line -->
          <li :class='{"box": !favoriteSite.active, "box active" : favoriteSite.active}'
              v-for="(favoriteSite, index) in favoriteSites" v-bind:key="index"
              @click="favoriteSite.active = !favoriteSite.active">
            <input type="checkbox" name="faves[]" @click="selectSite(favoriteSite)">
            <img :src="favoriteSite.public_path" width="75px" height="25px">
          </li>
          <!-- end of logos -->
        </ul>
      </div>
    </md-card-content>
    <md-card-actions>
      <md-button type="submit" class="md-primary md-raised next-step-login-process" :disabled='isDisabled' @click="saveNewTabAndSelectedCells">
        Next
      </md-button>
    </md-card-actions>
  </md-card>
</template>

<script>
import { FETCH_SITES } from '@/store/modules/admin/resources/sites/sitesActions.type';
import { CREATE_TAB_CELL, CREATE_USER_TAB } from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import loaderMixin from '@/mixins/loaderMixin';

export default {
  name: 'SelectFavorites',
  mixins: [
    loaderMixin,
  ],
  data: () => ({
    favoriteSites: {},
    selectedFavoriteSites: [],
    selected: 0,
    isDisabled: true,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.toggleNextBtn();
    this.fetchFavoriteSites();
  },
  methods: {
    fetchFavoriteSites() {
      this.$store.dispatch(IS_LOADING).then(() => {
        const params = {
          onlyBy: 'favorite_site',
        };
        this.$store.dispatch(FETCH_SITES, params)
          .then((response) => {
            this.favoriteSites = response.data.data;
            this.$log.info('FETCH_SITES', response);
            this.$store.dispatch(FINISHED_LOADING);
          })
          .catch((error) => {
            this.$log.error('FETCH_SITES', error);
            this.$store.dispatch(FINISHED_LOADING);
          });
      })
        .catch(() => {
          this.$store.dispatch(FINISHED_LOADING);
        });
    },
    selectSite(favoriteSiteObj) {
      if (this.selectedFavoriteSites.includes(favoriteSiteObj)) {
        this.selectedFavoriteSites = this.selectedFavoriteSites.filter(e => e !== favoriteSiteObj);
      } else {
        this.selectedFavoriteSites.push(favoriteSiteObj);
      }
      this.toggleNextBtn();
    },
    toggleNextBtn() {
      if (this.selectedFavoriteSites.length !== 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    saveNewTabAndSelectedCells() {
      if (this.user) {
        const payload = {
          user_id: this.user.id,
          name: this.user.username,
          position: 1,
          privatetab: 0,
          adminMode: false,
        };
        this.$store.dispatch(CREATE_USER_TAB, payload)
          .then((response) => {
            this.$log.info(CREATE_USER_TAB, payload);
            this.$toasted.show(response.message, {
              type: 'success',
              position: 'top-right',
              duration: 5000,
              icon: 'check',
            });
            this.selectedFavoriteSites.forEach((item) => {
              const payloadTabCell = {
                tab_id: response.data.userTab.id,
                site_id: item.id,
                link: item.address,
                drag: false,
              };
              this.$store.dispatch(CREATE_TAB_CELL, payloadTabCell)
                .then((response2) => {
                  this.$log.info(CREATE_TAB_CELL, payloadTabCell);
                  this.$toasted.show(response2.message, {
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                    icon: 'check',
                  });
                  this.$router.push('/user/step2');
                });
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/login-steps.scss';
</style>
